import React from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'
import SEO from '../components/seo'
import JoinUs from '../sections/joinUs'
import { colors, md } from '../common'

export default () => (
  <Layout>
    <SEO title="Returns" />
    <Wrap>
    <h1>Hassle free RETURN POLICY</h1>
    <div>
      <p>Whether you are shopping in our stores or through our webpage, Underwood’s stands behind every item we sell and want you to be truly delighted with your purchase. If you are not 100% satisfied with your purchase, you may return it in its original, new, and unworn condition within 30 days, for a full refund or exchange - no questions asked.</p>
      <p>Our policy allows for a return within 30 days of the original purchase. If 30 days have gone by since your purchase, unfortunately we can’t offer you a refund or exchange. To be eligible for a return, your item must be unused and in the same condition that you received it. It must also be in the original packaging. All refunds and exchanges must be accompanied by a sales receipt. Merchandise not in original condition, worn, adjusted or damaged cannot be refunded or exchanged. Timepieces must have all protective stickers in place. No refunds will be issued on Special Orders, or merchandise that has been engraved, sized or otherwise altered. This includes sizing of any timepieces.  Loose diamonds must be accompanied by the original laboratory grading report, or a $150 charge will be assessed to replace the report.</p>
      <h3>Additional non-returnable items:</h3>
      <ul>
      <li>Gift cards</li>
      <li>Sale items</li>
      </ul>

      <h3>Refunds (if applicable)</h3>
      <p>Once your return is received and inspected, we will notify you of the approval or rejection of your refund. If you are approved, then your refund will be processed, and a credit will automatically be applied to your credit card or original method of payment within two business days. Underwood’s does not save or store credit card information.</p>
      <h3>Gifts</h3>
      <p>If the item you are returning was purchased for you as a gift, you’ll receive a gift credit for the value of your return. Once the returned item is received, a gift credit on an account in your name will be mailed or emailed to you. If the gift item must be returned for a refund, we will issue the refund to the gift giver.</p>
      <h3>Shipping</h3>
      <p>To return your product you can bring it to the store or you can mail your product with a copy of the sales receipt you received to: UJ Corp  2044 San Marco Blvd. Jacksonville, FL  32207. You will be responsible for paying for your own shipping costs for returning your item. If you receive a refund, the cost of original shipping will be deducted from that refund.  If you are shipping an item over $150, you should consider using a trackable shipping service and purchasing shipping insurance. Without a trackable shipping service there is no guarantee that we will receive your returned item.</p>
    </div>
    </Wrap>
    <JoinUs />
  </Layout>
)

const Wrap = styled.div`
  padding: 4rem 8rem;

  h1 {
    color:${colors.green};
    text-align:center;
    text-transform:uppercase;
    padding-bottom:2rem;
  }

  ${md} {
    padding:1rem;
  }
`
